import { Fragment, useState, useEffect, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  {
    name: "Falcon",
    href: "#",
    submenu: [
      { name: "Boosters", href: "/falcon/boosters/" },
      { name: "Dragons", href: "/falcon/dragon/" },
      { name: "Stats", href: "/falcon/stats/" },
    ],
  },
  {
    name: "Starship",
    href: "#",
    submenu: [
      { name: "Boosters", href: "/starship/boosters/" },
      { name: "Ships", href: "/starship/ships/" },
      { name: "Stats", href: "/starship/stats/" },
    ],
  },
  { name: "Launches", href: "/launches/" },
  {
    name: "More",
    href: "#",
    submenu: [
      { name: "Historical Launch Calendar", href: "/launch-calendar/" },
      { name: "Pads", href: "/pads/" },
      { name: "Recovery Zones", href: "/recoveryzones/" },
      { name: "Additional Graphs", href: "/graphs/" },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const xLink = () => {
  window.open("https://x.com/BoosterTracker", "_blank");
};

export default function Navbar() {
  const location = useLocation();
  const [hovered, setHovered] = useState(null);
  const [currentNavItem, setCurrentNavItem] = useState("");
  const subMenuRefs = useRef({});

  useEffect(() => {
    const path = location.pathname;
    const matchedNav = navigation.find((nav) => path.startsWith(nav.href));

    if (matchedNav) {
      setCurrentNavItem(matchedNav.name);
    } else {
      const matchedSubNav = navigation
        .flatMap((nav) => nav.submenu || [])
        .find((subNav) => path.startsWith(subNav.href));
      if (matchedSubNav) {
        const parentNav = navigation.find((nav) => nav.submenu && nav.submenu.includes(matchedSubNav));
        if (parentNav) {
          setCurrentNavItem(parentNav.name);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    const updateSubMenuPosition = () => {
      Object.keys(subMenuRefs.current).forEach((key) => {
        const subMenu = subMenuRefs.current[key];
        if (subMenu) {
          const rect = subMenu.getBoundingClientRect();
          const menuWidth = subMenu.offsetWidth;
          const viewportWidth = window.innerWidth;

          if (rect.left < 0) {
            subMenu.classList.add("menu-right");
            subMenu.classList.remove("menu-left");
          } else if (rect.right + menuWidth > viewportWidth) {
            subMenu.classList.add("menu-left");
            subMenu.classList.remove("menu-right");
          } else {
            subMenu.classList.add("menu-right");
            subMenu.classList.remove("menu-left");
          }
        }
      });
    };

    window.addEventListener("resize", updateSubMenuPosition);
    updateSubMenuPosition();
    return () => {
      window.removeEventListener("resize", updateSubMenuPosition);
    };
  }, [hovered]);

  const handleMouseEnter = (name) => {
    setHovered(name);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const handleNavClick = (name) => {
    setCurrentNavItem(name);
  };

  return (
    <header className="sticky top-0 z-50 bg-gray-800">
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <a href="/">
                      <img className="block lg:hidden h-8 w-auto" src="/booster-trackertext.png" alt="Workflow" />
                      <img className="hidden lg:block h-8 w-auto" src="/booster-tracker.png" alt="Workflow" />
                    </a>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) =>
                        item.submenu ? (
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                            key={item.name}
                            onMouseEnter={() => handleMouseEnter(item.name)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <Menu.Button
                              className={classNames(
                                item.name === currentNavItem
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                            >
                              {item.name}
                            </Menu.Button>

                            <Transition
                              as={Fragment}
                              show={hovered === item.name}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                ref={(el) => (subMenuRefs.current[item.name] = el)}
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none menu-right"
                              >
                                {item.submenu.map((subItem) => (
                                  <Menu.Item key={subItem.name}>
                                    {({ active }) => (
                                      <Link
                                        onClick={() => handleNavClick(item.name)}
                                        to={subItem.href}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-700 hover:text-white"
                                        )}
                                        style={{ paddingLeft: "1.5rem" }} // Add indentation
                                      >
                                        {subItem.name}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.href}
                            onClick={() => handleNavClick(item.name)}
                            className={classNames(
                              item.name === currentNavItem
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.name === currentNavItem ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={xLink}
                  >
                    <img className="h-6 w-6" src="/x-logo-white.png" alt="" />
                  </button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <Disclosure.Button
                  as={Link}
                  to="/"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  Home
                </Disclosure.Button>
                {navigation.map((item) =>
                  item.submenu ? (
                    <div key={item.name}>
                      <Disclosure.Button
                        as="div"
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                      <div className="ml-4">
                        {item.submenu.map((subItem) => (
                          <Disclosure.Button
                            key={subItem.name}
                            as={Link}
                            to={subItem.href}
                            onClick={() => handleNavClick(item.name)}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                          >
                            {subItem.name}
                          </Disclosure.Button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      onClick={() => handleNavClick(item.name)}
                      className={classNames(
                        item.name === currentNavItem
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.name === currentNavItem ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
}
